import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import kayalogo from '../../../src/assets/kayalogo.jpeg';
import chatbotBg from '../../../src/assets/chatbot-bg.jpg';
import { Checkbox, Stack } from '@mui/material';
import { ChatBubble } from '@mui/icons-material';
import { styled } from '@mui/system';
import Chatbot from './Index';
import './index.css';
import Confirm from '../messages';
// Custom styles for the animated button
const AnimatedButtonWrapper = styled('div')`
  display: inline-block;
  position: relative;
`;
const RoundBackground = styled('div')`
  position: absolute;
  right: -30px;
  z-index: 20;
  top: 50%;
  transform: translateY(-50%);
  background-color: #3a5192;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 0.1s ease-in-out;
`;
const pages = [
  { name: 'Home', link: 'https://kayawellbeingindex.com/about-kaya/cost-of-hc/' },
  { name: 'Blog', link: 'https://kayawellbeingindex.com/blogs/' },
];
function Home() {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const [isOpen, setIsOpen] = React.useState(false);

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [hovered, setHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <Stack>
      <AppBar
        position="static"
        sx={{ background: '#fff', zIndex: '333', color: '#000' }}
      >
        <Container maxWidth="xl" sx={{ width: 'calc(100% - 48px)' }}>
          <Toolbar disableGutters>
            <img style={{ height: '100px' }} src={kayalogo} alt="" />

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'flex-end',
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                  flexGrow: '1',
                  justifyContent: 'flex-end',
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                    <a
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      href={page.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography textAlign="center">{page.name}</Typography>
                    </a>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                justifyContent: 'flex-end',
                display: { xs: 'none', md: 'flex' },
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page.name}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: '#000', display: 'block' }}
                >
                  <a
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    href={page.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {page.name}
                  </a>
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        p={4}
        display={'flex'}
        height={'calc(100vh - 164px)'}
        overflowY={'scroll'}
        sx={{
          '@media (max-width: 960px)': {
            flexDirection: 'column',
            gap: '30px',
          },
        }}
      >
        <Stack flex={2} justifyContent={'center'} alignItems={'center'}>
          <Typography
            variant="h4"
            mb={1}
            fontFamily={'arial'}
            fontWeight={'800'}
            sx={{
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
            }}
          >
            What is the cost of your human capital?
          </Typography>

          <ul class="disc-list">
            <li>
              Are you optimising your employee engagement, curbing attition, and
              trackling absenteeism?
            </li>
            <li>
              If you are not you are eroding the value of your human capital.
            </li>
            <li>Not doing so erodes productivity and profitability.</li>
          </ul>
          <Typography
            variant="body"
            mb={1}
            fontFamily={'poppins'}
            color={'rgb(105, 105, 105)'}
            textAlign={'justify'}
            fontWeight={'400'}
            sx={{
              fontSize: { xs: '15px', sm: '20px', md: '25px' },
            }}
          >
            Unlock personalised insights in just under <b>3 minutes!</b> Answer
            <b> 15 simple questions</b>, and we'll craft your report. Your
            <b> data </b>
            is strictly <b>confidential</b> - we <b> won't share </b>it with
            anyone. Upon survey completion, expect your <b> tailored</b> report
            to be delivered straight <b>to your inbox!</b>
          </Typography>
          <Typography
            fontWeight={'400'}
            color={'rgb(105, 105, 105)'}
            fontFamily={'poppins'}
            sx={{
              fontSize: { xs: '15px', sm: '20px', md: '25px', width: '100%' },
            }}
            textAlign={'center'}
          >
            Let's embark on this journey together.
          </Typography>
          <Box
            p={2}
            display={'flex'}
            width={'100%'}
            justifyContent={'center'}
            className="footer2"
          >
            <Typography textAlign={'center'}>
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />{' '}
              I accept the{' '}
              <a
                href={`${process.env.REACT_APP_BASE_URL}/assets/PLATFORM_TOS.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Services
              </a>{' '}
              and{' '}
              <a
                href={`${process.env.REACT_APP_BASE_URL}/assets/Privacy-policy-Company.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </Typography>
          </Box>
          <Stack mt={2}>
            <AnimatedButtonWrapper>
              <Button
                size="large"
                variant="contained"
                disabled={!checked}
                color="primary"
                onClick={() => setIsOpen(!isOpen)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                  fontSize: '20px',
                  backgroundColor: '#3a5192',
                  '&:hover': {
                    backgroundColor: '#3a5192',
                  },
                  //   transform: hovered ? 'scale(1.1)' : 'scale(1)',
                  paddingRight: hovered ? '50px' : '18px',
                  transition: 'paddingRight 0.1s ease-in-out',
                }}
              >
                Calculate the value of your human capital
                <RoundBackground visible={hovered}>
                  <IconButton aria-label="icon">
                    <ChatBubble sx={{ fontSize: '30px', color: '#fff' }} />
                  </IconButton>
                </RoundBackground>
              </Button>
            </AnimatedButtonWrapper>
          </Stack>
        </Stack>
        <Box display={'flex'} flex={1}>
          <img
            style={{ width: '100%', objectFit: 'cover' }}
            src={chatbotBg}
            alt=""
          />
        </Box>
      </Box>
      <Chatbot isOpen={isOpen} setIsOpen={setIsOpen} />
    </Stack>
  );
}
export default Home;
