import React, { useEffect, useState } from 'react';
import ChatBot, { Steps, step, Step, steps } from 'react-simple-chatbot';
import parse from 'html-react-parser';
import './index.css';
import operator from '../../../src/assets/operator.jpg';
import user from '../../../src/assets/userBOT.jpg';
import search from '../../../src/assets/search-chatbot.png';
import solve from '../../../src/assets/solve.png';
import boss from '../../../src/assets/boss.png';
import folks from '../../../src/assets/folks.png';
import toss from '../../../src/assets/toss.png';
import mission from '../../../src/assets/mission.png';
import saga from '../../../src/assets/saga.png';
import calculate from '../../../src/assets/calculate.png';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Dialog } from '@mui/material';
import { REACT_APP_CHATBOT_BASE_URL } from '../../config';
import Confirm from '../messages';
import Industry from '../messages/industries';

export default function Chatbot({ setIsOpen, isOpen }) {
  const [allSteps, setAllSteps] = useState([]);
  console.log('steps', Steps, step, Step, steps);
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    // Make API request
    const fetchPosts = async () => {
      await axios
        .get(`${REACT_APP_CHATBOT_BASE_URL}/chatbot/getChatbotTexts`)
        .then((response) => {
          // Store API response in state
          setApiData(response.data);
          setAllSteps([
            {
              id: '1',
              component: (
                <CustomBot
                  message={response.data[1]}
                  // previousValue="{previousValue}"
                  color="#000"
                  background="#98dac8"
                />
              ),
              trigger: '2',
            },

            {
              id: 'temp',
              message: `${response.data[56]}`,
              trigger: '2',
            },
            {
              id: '2',
              user: true,
              placeholder: 'Please enter your name',

              trigger: '3',
              validator: (value, steps, step) => {
                if (!isNaN(value)) {
                  handleInput(value, steps, step);
                  return 'Name should be not be a number';
                }
                handleInput(value, steps, step);
                setUsername(value);
                return true;
              },
            },
            {
              id: '3',
              message: `${response.data[3]}`,
              trigger: '4',
            },
            {
              id: '4',
              options: [
                {
                  value: 1,
                  label: (
                    <CustomOptionLabel
                      option={{
                        label: `${response.data['4-1']}`,
                        value: '4-1',
                        img: search,
                      }}
                    />
                  ),
                  component: (
                    <CustomOptionLabel
                      optionAnswer={true}
                      option={{
                        label: `${response.data['4-1']}`,
                        value: '4-1',
                        img: search,
                      }}
                    />
                  ),
                  trigger: '5',
                },
                //I am trying to solve this problem
                {
                  value: 2,
                  label: (
                    <CustomOptionLabel
                      option={{
                        label: `${response.data['4-2']}`,
                        value: '4-2',
                        img: solve,
                      }}
                    />
                  ),
                  trigger: '5',
                  component: (
                    <CustomOptionLabel
                      optionAnswer={true}
                      option={{
                        label: `${response.data['4-2']}`,
                        value: '4-2',
                        img: solve,
                      }}
                    />
                  ),
                },
                {
                  value: 3,
                  label: (
                    <CustomOptionLabel
                      option={{
                        label: `${response.data['4-3']}`,
                        value: '4-3',
                        img: boss,
                      }}
                    />
                  ),
                  component: (
                    <CustomOptionLabel
                      optionAnswer={true}
                      option={{
                        label: `${response.data['4-3']}`,
                        value: '4-3',
                        img: boss,
                      }}
                    />
                  ),
                  trigger: '5',
                },
              ],
            },
            {
              id: '5',
              component: (
                <CustomBot
                  message={`${response.data[5]}`}
                  color="#000"
                  background="#98dac8"
                  img={folks}
                />
              ),
              trigger: '55',
            },
            {
              id: '6',
              component: (
                <CustomBot
                  message={`${response.data[7]}`}
                  // previousValue="{previousValue}"
                  img={mission}
                  color="#000"
                  background="#98dac8"
                />
              ),
              trigger: '66',
            },
            {
              id: '55',
              user: true,
              validator: (value) => handleOnChange(value, 'employee_count'),
              placeholder: 'Please enter team count',
              trigger: '6',
            },
            {
              id: '66',
              user: true,
              validator: (value) => handleOnChange(value, 'average_salary'),
              placeholder: 'Please enter average annual salary',
              trigger: '9',
            },
            {
              id: '77',
              user: true,
              validator: (value) => handleOnChange(value, 'email'),
              placeholder: 'Enter an email address',
              trigger: '9',
            },
            //step 3
            {
              id: '9',
              component: (
                <CustomBot
                  message={`${response.data[9]}`}
                  // previousValue="{previousValue}"
                  color="#000"
                  background="#98dac8"
                  img={calculate}
                />
              ),
              trigger: '10',
            },
            {
              id: '10',
              user: true,
              validator: (value) => handleOnChange(value, 'days_lost'),
              placeholder: 'Enter days lost',
              trigger: '11',
            },
            {
              id: '11',
              component: (
                <CustomBot
                  color="#000"
                  background="#98dac8"
                  size={true}
                  lost={true}
                  cur={'10'}
                />
              ),
              trigger: '12',
            },
            {
              id: '12',
              component: (
                <CustomBot
                  noAvatar
                  message={`${response.data[12]}`}
                  // previousValue="{previousValue}"
                  color="#000"
                  background="#98dac8"
                  img={saga}
                />
              ),
              trigger: '13',
            },
            {
              id: '13',
              user: true,
              validator: (value) => handleOnChange(value, 'resignation_count'),
              placeholder: 'Enter number of resignation',
              trigger: '14',
            },
            {
              id: '14',
              component: (
                <CustomBot
                  size={true}
                  cur={'13'}
                  color="#304073"
                  background="#dcdaf2"
                />
              ),
              trigger: '15',
            },
            {
              id: '15',
              component: (
                <CustomBot
                  message={`${response.data[15]}`}
                  // previousValue="{previousValue}"
                  noAvatar
                  color="#304073"
                  background="#dcdaf2"
                />
              ),
              trigger: '16',
            },
            yesNo(16, 17, 'missing_targets', 19),
            {
              id: '17',
              component: (
                <CustomBot
                  message={`${response.data[17]}`}
                  // previousValue="{previousValue}"
                  color="#304073"
                  background="#dcdaf2"
                />
              ),
              trigger: '18',
            },
            {
              id: '18',
              user: true,
              validator: (value) => handleOnChange(value, 'missing_percentage'),
              placeholder: 'Enter missing percentage',
              trigger: '19',
            },

            {
              id: '19',
              component: (
                <CustomBot
                  message={`${response.data[19]}`}
                  // previousValue="{previousValue}"
                  color="#304073"
                  background="#dcdaf2"
                />
              ),
              trigger: '20',
            },
            yesNo(20, 21, 'measure_performance', 200),
            {
              id: '200',
              component: (
                <CustomBot
                  message={`Based on your perception of how engaged the team is, would you say they are`}
                  // previousValue="{previousValue}"
                  color="#304073"
                  background="#dcdaf2"
                />
              ),
              trigger: '201',
            },
            {
              id: '201',
              options: [
                {
                  value: 1,
                  label: (
                    <CustomOptionLabel
                      option={{
                        label: `Not engaged`,
                        value: '18',
                      }}
                    />
                  ),
                  component: (
                    <CustomOptionLabel
                      optionAnswer={true}
                      option={{
                        label: `Not engaged`,
                        value: '18',
                      }}
                    />
                  ),
                  trigger: '32',
                },
                //I am trying to solve this problem
                {
                  value: 2,
                  label: (
                    <CustomOptionLabel
                      option={{
                        label: `Poorly engaged`,
                        value: '42',
                      }}
                    />
                  ),
                  trigger: '32',
                  component: (
                    <CustomOptionLabel
                      optionAnswer={true}
                      option={{
                        label: `Poorly engaged`,
                        value: '42',
                      }}
                    />
                  ),
                },
                {
                  value: 3,
                  label: (
                    <CustomOptionLabel
                      option={{
                        label: `Engaged enough`,
                        value: '60',
                      }}
                    />
                  ),
                  component: (
                    <CustomOptionLabel
                      optionAnswer={true}
                      option={{
                        label: `Engaged enough`,
                        value: '60',
                      }}
                    />
                  ),
                  trigger: '32',
                },
                {
                  value: 4,
                  label: (
                    <CustomOptionLabel
                      option={{
                        label: `Very engaged`,
                        value: '75',
                      }}
                    />
                  ),
                  component: (
                    <CustomOptionLabel
                      optionAnswer={true}
                      option={{
                        label: `Very engaged`,
                        value: '75',
                      }}
                    />
                  ),
                  trigger: '32',
                },
                {
                  value: 5,
                  label: (
                    <CustomOptionLabel
                      option={{
                        label: `Exceptionally engaged`,
                        value: '91',
                      }}
                    />
                  ),
                  component: (
                    <CustomOptionLabel
                      optionAnswer={true}
                      option={{
                        label: `Exceptionally engaged`,
                        value: '91',
                      }}
                    />
                  ),
                  trigger: '32',
                },
              ],
            },
            {
              id: '21',
              component: (
                <CustomBot
                  message={`${response.data[21]}`}
                  // previousValue="{previousValue}"
                  color="#304073"
                  background="#dcdaf2"
                />
              ),
              trigger: '221',
            },
            {
              id: '221',
              user: true,
              validator: (value) => handleOnChange(value, 'performance_score'),
              placeholder: 'Enter performace score',
              trigger: '22',
            },
            /****************/
            {
              id: '22',
              component: (
                <CustomBot
                  message={`${response.data[22]}`}
                  color="#304073"
                  background="#dcdaf2"
                />
              ),
              trigger: '25',
            },

            /****************/

            // {
            //   id: '24',
            //   component: (
            //     <CustomBot
            //       message={`${response.data[24]}`}
            //       color="#304073"
            //       background="#dcdaf2"
            //     />
            //   ),
            // },
            {
              id: '25',
              user: true,
              trigger: '32',
              validator: (value) =>
                handleOnChange(value, 'engagment_score_last_year'),
              placeholder: 'Enter score',
            },
            // {
            //   id: '29',
            //   component: (
            //     <CustomBot
            //       message={`${response.data[29]}`}
            //       color="#304073"
            //       background="#dcdaf2"
            //     />
            //   ),
            //   trigger: '32',
            // },
            // {
            //   id: '30',
            //   component: (
            //     <CustomBot
            //       message={`${response.data[30]}`}
            //       color="#304073"
            //       background="#dcdaf2"
            //     />
            //   ),
            //   trigger: '31',
            // },
            // {
            //   id: '31',
            //   user: true,
            //   trigger: '32',
            //   validator: (value) =>
            //     handleOnChange(value, 'score_down_percentage'),
            //   placeholder: 'Enter score',
            // },
            {
              id: '32',
              component: (
                <CustomBot
                  message={`${response.data[32]}`}
                  color="#304073"
                  background="#dcdaf2"
                />
              ),
              trigger: '351',
            },
            yesNo(351, 352, 'customer_experience_measure', 470),
            {
              id: '470',
              component: (
                <CustomBot
                  message={`Based on your perception of how customers feel about your company, would you say they are`}
                  // previousValue="{previousValue}"
                  color="#304073"
                  background="#dcdaf2"
                />
              ),
              trigger: '471',
            },
            {
              id: '471',
              options: [
                {
                  value: 1,
                  label: (
                    <CustomOptionLabel
                      option={{
                        label: `Extremely unhappy`,
                        value: '18',
                      }}
                    />
                  ),
                  component: (
                    <CustomOptionLabel
                      optionAnswer={true}
                      option={{
                        label: `Extremely unhappy`,
                        value: '18',
                      }}
                    />
                  ),
                  trigger: '472',
                },
                //I am trying to solve this problem
                {
                  value: 2,
                  label: (
                    <CustomOptionLabel
                      option={{
                        label: `Very unhappy`,
                        value: '42',
                      }}
                    />
                  ),
                  trigger: '472',
                  component: (
                    <CustomOptionLabel
                      optionAnswer={true}
                      option={{
                        label: `Very unhappy`,
                        value: '42',
                      }}
                    />
                  ),
                },
                {
                  value: 3,
                  label: (
                    <CustomOptionLabel
                      option={{
                        label: `Happy enough`,
                        value: '60',
                      }}
                    />
                  ),
                  component: (
                    <CustomOptionLabel
                      optionAnswer={true}
                      option={{
                        label: `Happy enough`,
                        value: '60',
                      }}
                    />
                  ),
                  trigger: '472',
                },
                {
                  value: 4,
                  label: (
                    <CustomOptionLabel
                      option={{
                        label: `Very Happy`,
                        value: '75',
                      }}
                    />
                  ),
                  component: (
                    <CustomOptionLabel
                      optionAnswer={true}
                      option={{
                        label: `Very Happy`,
                        value: '75',
                      }}
                    />
                  ),
                  trigger: '472',
                },
                {
                  value: 5,
                  label: (
                    <CustomOptionLabel
                      option={{
                        label: `Exceptionally Happy`,
                        value: '91',
                      }}
                    />
                  ),
                  component: (
                    <CustomOptionLabel
                      optionAnswer={true}
                      option={{
                        label: `Exceptionally Happy`,
                        value: '91',
                      }}
                    />
                  ),
                  trigger: '472',
                },
              ],
            },
            {
              id: '352',
              component: (
                <CustomBot
                  message={`${response.data[321]}`}
                  color="#304073"
                  background="#dcdaf2"
                />
              ),
              trigger: '35',
            },
            // {
            //   id: '33',
            //   user: true,
            //   trigger: '34',
            //   validator: (value) => handleOnChange(value, 'customer_experience_score'),
            // },
            // {
            //   id: '34',
            //   component: (
            //     <CustomBot
            //       message={"Oh Great do, spill the beans - what's the score"}
            //       color="#304073"
            //       background="#dcdaf2"
            //     />
            //   ),
            //   trigger: '35',
            // },
            {
              id: '35',
              user: true,
              validator: (value) =>
                handleOnChange(value, 'customer_experience_score'),
              trigger: '36',
              placeholder: 'Enter customer experience score',
            },

            /****************/
            {
              id: '36',
              component: (
                <CustomBot
                  message={`${response.data[36]}`}
                  color="#304073"
                  background="#dcdaf2"
                />
              ),
              trigger: '39',
            },
            /****************/

            {
              id: '39',
              user: true,
              validator: (value) =>
                handleOnChange(value, 'customer_score_last_year '),
              trigger: '472',
              placeholder: 'Enter score',
            },
            {
              id: '472',
              component: (
                <CustomBot
                  message={`${response.data[472]}`}
                  color="#304073"
                  background="#dcdaf2"
                />
              ),
              trigger: '473',
            },
            {
              id: '473',
              user: true,
              validator: (value) => handleOnChange(value, 'annual_revenue'),
              trigger: '474',
              placeholder: 'Enter annual revenue',
            },
            {
              id: '474',
              component: (
                <CustomBot
                  message={`${response.data[474]}`}
                  color="#304073"
                  background="#dcdaf2"
                />
              ),
              trigger: '475',
            },
            {
              id: '475',
              user: true,
              validator: (value) => handleOnChange(value, 'customers_count'),
              trigger: '477T',
              placeholder: 'Enter customer count',
            },
            {
              id: '477T',
              component: <Industry />,
            },
            {
              id: '477',
              component: (
                <CustomBot
                  message={`${response.data[55]}`}
                  email={true}
                  color="#000"
                  background="#98dac8"
                  img={toss}
                />
              ),

              trigger: '478',
            },
            {
              id: '478',
              user: true,
              validator: (value) => handleOnChange(value, 'email'),
              placeholder: 'Enter an email address',
              trigger: '479C',
            },
            {
              id: '479C',

              component: (
                <Confirm
                  result={result}
                  message={response.data[56]}
                  data={data}
                  setData={setData}
                />
              ),
              // trigger: '479',
            },
            {
              id: '479',
              waitAction: true,
              asMessage: true,
              component: (
                <CustomAsyncBot
                  all={all}
                  message={'Calculating...'}
                  color={'brown'}
                  background={'#ccf0e4'}
                  img={folks}
                />
              ),
            },
            {
              id: '480',
              waitAction: true,
              asMessage: true,
              component: (
                <CustomBot
                  noAvatar
                  result
                  message={`${response.data[47]}`}
                  color="#000"
                  background="#98dac8"
                  final={true}
                />
              ),
            },
            {
              id: '47',
              delay: 1000,
              waitAction: true,
              asMessage: true,
              component: (
                <CustomResult
                  noAvatar
                  message={`${response.data[47]}`}
                  result
                  color="#000"
                  background="#98dac8"
                />
              ),

              trigger: '48',
            },

            {
              id: '48',
              component: (
                <CustomBot
                  message={`${response.data[48]}`}
                  color="#000"
                  background="#98dac8"
                />
              ),
              trigger: '49',
            },
            yesNo(49, 50, '', 99),
            {
              id: '50',
              component: (
                <CustomBotLink
                  message={`${response.data[50]}`}
                  link={'https://calendly.com/virtualcuppa/45'}
                  detail={`${response.data[51]}`}
                  color="#000"
                  background="#98dac8"
                />
              ),
              // trigger: '99',
              // end: true,
            },
            {
              id: '99',
              component: (
                <CustomBot
                  message={'Thank you!'}
                  color="#000"
                  background="#98dac8"
                />
              ),
              // end: true,
              // end: true,
            },
          ]);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchPosts();
  }, [isOpen]);
  console.log('apiData', allSteps);
  const [companySize, setCompany] = useState();
  const [completedUpdate, setCompletedUpdate] = useState(false);
  const [name, setUsername] = useState('test');
  const [engagement, setEngagement] = useState('4-1');
  const [employee_count, setemployee_count] = useState('1');
  const [average_salary, setaverage_salary] = useState('1');
  const [email, setemail] = useState('test@gmail.com');
  const [days_lost, setdays_lost] = useState('1');
  const [annual_revenue, setannual_revenue] = useState('1');
  const [customers_count, setcustomers_count] = useState('1');
  const [resignation_count, setresignation_count] = useState('1');
  const [missing_targets, setmissing_targets] = useState('1');
  const [
    customer_experience_measure,
    setcustomer_experience_measure,
  ] = useState('Y');
  const [missing_percentage, setmissing_percentage] = useState('1');
  const [measure_performance, setmeasure_performance] = useState('N');
  const [performance_score, setperformance_score] = useState('1');
  const [score_change, setscore_change] = useState('up');
  const [score_up_percentage, setscore_up_percentage] = useState('1');
  const [score_down_percentage, setscore_down_percentage] = useState('1');
  const [customer_experience_score, setcustomer_experience_score] = useState(
    '1'
  );
  const [customer_experience_change, setcustomer_experience_change] = useState(
    'up'
  );
  const [
    customer_experience_up_percentage,
    setcustomer_experience_up_percentage,
  ] = useState('1');
  const [
    customer_experience_down_percentage,
    setcustomer_experience_down_percentage,
  ] = useState('1');
  useEffect(() => {
    if (completedUpdate) {
      setCompletedUpdate(false);
    }
  }, [completedUpdate]);

  const handleOnChange = (value, name) => {
    if (name === 'employee_count') {
      setemployee_count(value);
    } else if (name === 'average_salary') {
      setaverage_salary(value);
    } else if (name === 'email') {
      setemail(value);
    } else if (name === 'days_lost') {
      setdays_lost(value);
    } else if (name === 'resignation_count') {
      setresignation_count(value);
    } else if (name === 'score_down_percentage') {
      setscore_down_percentage(value);
    } else if (name === 'performance_score') {
      setperformance_score(value);
    } else if (name === 'score_up_percentage') {
      setscore_up_percentage(value);
    } else if (name === 'missing_percentage') {
      setmissing_percentage(value);
    } else if (name === 'customer_experience_score') {
      setcustomer_experience_score(value);
    } else if (name === 'customer_experience_down_percentage') {
      setcustomer_experience_down_percentage(value);
    } else if (name === 'customer_experience_up_percentage') {
      setcustomer_experience_up_percentage(value);
    } else if (name === 'annual_revenue') {
      setannual_revenue(value);
    } else if (name === 'customers_count') {
      setcustomers_count(value);
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // if ((!(name === 'email') || !(name === 'name')) && isNaN(value)) {
    if (name === 'email' && !emailRegex.test(value)) {
      return 'Please enter a valid email address';
    } else if (
      (name === 'employee_count' ||
        name === 'average_salary' ||
        name === 'resignation_count' ||
        name === 'missing_percentage' ||
        name === 'performance_score' ||
        name === 'score_up_percentage' ||
        name === 'score_down_percentage' ||
        name === 'customer_experience_score' ||
        name === 'customer_experience_up_percentage' ||
        name === 'customer_experience_down_percentage' ||
        name === 'annual_revenue' ||
        name === 'customers_count' ||
        name === 'days_lost') &&
      isNaN(value)
    ) {
      return 'Please enter a valid number';
    } else {
      setCompany(value);
      setCompletedUpdate(true);
    }
    return true;
  };

  const CustomBotLink = ({ message, img, noAvatar, link, detail }) => {
    return (
      <div
        style={{
          fontSize: '15px',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'flex-end',
          gap: '10px',
          marginLeft: noAvatar ? '52px' : '0px',
        }}
      >
        {!noAvatar && (
          <img
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50% 50% 0px',
              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 2px 0px',
            }}
            src={operator}
            alt=""
          />
        )}
        <div
          style={{
            backgroundColor: '#3a5192',
            borderRadius: '18px 18px 18px 0px',
            color: '#fff',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            justifyContent: 'space-around',
            alignItems: 'center',
            width: '100%',
            padding: '15px',
          }}
        >
          {img && (
            <img
              style={{
                width: '40px',
                height: '40px',
              }}
              src={img}
              alt=""
            />
          )}
          <div
            className="link-outlook"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '5px',
              textAlign: 'left',
              width: '100%',
            }}
          >
            <a
              href={link}
              style={{ textDecoration: 'inherit' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {message}
            </a>
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              color="#fff"
              size="1x"
            />
          </div>
          <div> {detail || ''}</div>
        </div>
      </div>
    );
  };
  const CustomBot = ({
    message,
    img,
    color,
    background,
    noAvatar,
    cur,
    size,
    steps,
    lost,
    final,
    result,
    triggerNextStep,
  }) => {
    useEffect(() => {
      final &&
        triggerNextStep({
          value: `${steps[477]?.value}`,
          trigger: '47',
        });
    }, []);
    const calculatePercentage = (number1, number2) => {
      if (number2 === 0) {
        return 'Cannot divide by zero';
      }

      const percentage = (number1 / number2) * 100;
      return `${percentage}`;
    };
    let percent1 = calculatePercentage(steps['10']?.value, 250);
    let percent2 = calculatePercentage(steps['13']?.value, employee_count);
    console.log('percent:', percent1, steps['10']?.value, 250);
    console.log('percent:', percent2, steps['13']?.value, employee_count);
    // setData(steps);
    // Implement your custom rendering logic here
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'flex-end',
          gap: '10px',
          marginLeft: noAvatar && !result ? '52px' : '0px',
        }}
      >
        {!noAvatar && (
          <img
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50% 50% 0px',
              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 2px 0px',
            }}
            src={operator}
            alt=""
          />
        )}
        <div
          style={{
            backgroundColor: '#3a5192',
            borderRadius: '18px 18px 18px 0px',
            color: '#fff',
            display: 'flex',
            gap: '10px',
            justifyContent: 'space-around',
            alignItems: 'center',
            width: '100%',
            fontSize: '15px',
            padding: '15px',
          }}
        >
          {img && (
            <img
              style={{
                width: '40px',
                height: '40px',
              }}
              src={img}
              alt=""
            />
          )}
          <div>
            {size
              ? lost
                ? percent1 < 6
                  ? "Oh, that's not bad then..."
                  : percent1 < 10 && percent1 > 5
                  ? 'Hmmm ok!'
                  : 'Woah! alrighty then..'
                : percent2 < 6
                ? "Oh, that's not bad then..."
                : percent2 < 10 && percent2 > 5
                ? 'Hmmm ok!'
                : 'Woah! alrighty then..'
              : message || ''}
          </div>
        </div>
      </div>
    );
  };
  async function myAsyncFunction() {
    // Your async logic here
    // Simulating an async task with a delay of 2 seconds
  }
  const [result, setResult] = useState('');
  const [all, setAll] = useState();
  useEffect(() => {
    setAll({
      name: name,
      engagement: engagement,
      employee_count: employee_count,
      average_salary: average_salary,
      email: email,
      days_lost: days_lost,
      resignation_count: resignation_count,
      score_down_percentage: score_down_percentage,
      performance_score: performance_score,
      measure_performance: measure_performance,
      score_up_percentage: score_up_percentage,
      missing_targets: missing_targets,
      missing_percentage: missing_percentage,
      customer_experience_score: customer_experience_score,
      customer_experience_down_percentage: customer_experience_down_percentage,
      customer_experience_up_percentage: customer_experience_up_percentage,
      annual_revenue: annual_revenue,
      customers_count: customers_count,
    });
  }, [
    name,
    engagement,
    employee_count,
    average_salary,
    email,
    days_lost,
    resignation_count,
    score_down_percentage,
    performance_score,
    measure_performance,
    score_up_percentage,
    missing_targets,
    missing_percentage,
    customer_experience_score,
    customer_experience_down_percentage,
    customer_experience_up_percentage,
    annual_revenue,
    customers_count,
  ]);
  const CustomResult = (props) => {
    useEffect(() => {
      props.triggerNextStep({
        value: `some data`,
        trigger: '48',
      });
    }, []);
    return <div>{parse(props.steps['479']?.value)}</div>;
  };
  const [data, setData] = useState({
    name: { label: 'Enter name', value: 'a' },
    age: { label: 'Enter age', value: 'b' },
    score: { label: 'Enter score', value: 'c' },
  });
  const CustomAsyncBot = (props) => {
    console.log(props.steps);
    let finalData = {
      name: props.steps['2']?.value || '',
      engagement: props.steps['4']?.message?.props?.option?.label || '',
      employee_count:
        props.steps['479C'].value?.employee_count?.value ||
        props.steps['55']?.value ||
        '',
      average_salary:
        props.steps['479C'].value?.average_salary?.value ||
        props.steps['66']?.value ||
        '',
      email:
        props.steps['479C'].value?.email?.value ||
        props.steps['478']?.value ||
        '',
      days_lost:
        props.steps['479C'].value?.days_lost?.value ||
        props.steps['10']?.value ||
        '',
      resignation_count:
        props.steps['479C'].value?.resignation_count?.value ||
        props.steps['13']?.value ||
        '',
      performance_score:
        props.steps['479C'].value?.performance_score?.value ||
        props.steps['221']?.value ||
        '',
      measure_performance: props.steps['20']?.value === 1 ? 'Y' : 'N' || '',
      engagment_score_last_year:
        props.steps['479C'].value?.engagment_score_last_year?.value ||
        props.steps['25']?.value ||
        '',
      missing_targets: props.steps[16]?.value === 1 ? 'Y' : 'N' || '',
      missing_percentage:
        props.steps['479C'].value?.missing_percentage?.value ||
        props.steps['18']?.value ||
        '',
      customer_experience_measure:
        props.steps['351']?.value === 1 ? 'Y' : 'N' || '',

      customer_experience_score:
        props.steps['479C'].value?.customer_experience_score?.value ||
        props.steps['35']?.value ||
        '',
      customer_score_last_year:
        props.steps['479C'].value?.customer_score_last_year?.value ||
        props.steps['39']?.value ||
        '',
      annual_revenue:
        props.steps['479C'].value?.annual_revenue?.value ||
        props.steps['473']?.value ||
        '',
      customers_count:
        props.steps['479C'].value?.customers_count?.value ||
        props.steps['475']?.value ||
        '',
      industry_id:
        props.steps['479C'].value?.industry_id?.value ||
        props.steps['477T'].value?.value ||
        '',
    };
    useEffect(() => {
      const runAsyncFunction = async () => {
        try {
          await axios
            .post(
              `${REACT_APP_CHATBOT_BASE_URL}/chatbot/saveChatbotData`,
              finalData
            )
            .then((response) => {
              console.log('response', response);
              setResult(response.data);
              props.triggerNextStep({
                value: `${response.data}`,
                trigger: '480',
              });

              const updatedAllSteps = [...allSteps]; // Create a copy of the allSteps array

              updatedAllSteps[0] = {
                ...updatedAllSteps[0],
                message: 'New message for the first object',
              }; // Update the message property of the first object
              setAllSteps(updatedAllSteps); // Update the state with the modified array
            });
        } catch (error) {
          console.log(error);
        }
        // Run your async function here
        // Move to the next step or trigger programmatically
      };

      runAsyncFunction();
    }, [customers_count, annual_revenue]);
    const handleCalculate = () => {
      console.log(props.all);
    };
    return <div onClick={handleCalculate}>Calculating...</div>;
  };
  const CustomOptionLabel = ({
    option,
    score_change,
    customer_experience_change,
    value,
    optionAnswer,
    result,
  }) => {
    // Implement your custom rendering logic here
    const handleClick = () => {
      if (value === 'missing_targets' && result === 'Y') {
        setmissing_targets('Y');
      } else if (value === 'missing_targets' && result === 'N') {
        setmissing_targets('N');
      }

      if (value === 'measure_performance' && result === 'Y') {
        setmeasure_performance('Y');
      } else if (value === 'measure_performance' && result === 'N') {
        setmeasure_performance('N');
      }

      if (score_change === 'up') {
        setscore_change('up');
      } else if (score_change === 'down') {
        setscore_change('down');
      } else if (score_change === 'stay') {
        setscore_change('stay');
      }

      if (customer_experience_change === 'up') {
        setcustomer_experience_change('up');
      } else if (customer_experience_change === 'down') {
        setcustomer_experience_change('down');
      } else if (customer_experience_change === 'stay') {
        setcustomer_experience_change('stay');
      }
    };
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          cursor: 'pointer',
          alignItems: optionAnswer ? 'flex-end' : 'unset',
          justifyContent: optionAnswer ? 'flex-end' : 'unset',
        }}
      >
        <div
          onClick={handleClick}
          style={{
            backgroundColor: optionAnswer ? '#98dac8' : '#fddad8',
            borderRadius: optionAnswer ? '18px 18px 0px' : '10px',
            color: optionAnswer ? 'rgb(74, 74, 74)' : 'brown',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            fontSize: '15px',
            width: optionAnswer ? 'unset' : '100%',
            padding: '10px',
            gap: optionAnswer ? '5px' : '0px',
          }}
        >
          <div>{option.label}</div>
          {option.img && (
            <img
              src={option.img}
              style={{ width: '40px', height: '40px' }}
              alt=""
            />
          )}
        </div>
        {optionAnswer && (
          <img src={user} style={{ width: '50px', height: '50px' }} alt="" />
        )}
      </div>
    );
  };
  const optionStyle = {
    backgroundColor: '#fddad8',
    color: 'brown',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '5px',
  };

  const handleChatEnd = (steps) => {
    setIsOpen(false);
  };
  const handleOptionClick = () => {
    setIsOpen(false);
    // Perform additional actions when an option is clicked
  };
  const handleInput = (value, steps, step) => {};
  const yesNo = (id, trigger, value, jump) => {
    return {
      id: id,
      options: [
        {
          value: 1,
          label: (
            <CustomOptionLabel
              value={value}
              result="Y"
              option={{
                label: 'Yes',
              }}
            />
          ),
          component: (
            <CustomOptionLabel
              optionAnswer={true}
              value={value}
              result="Y"
              option={{
                label: 'Yes',
              }}
            />
          ),
          trigger: trigger,
        },
        {
          value: 2,
          label: (
            <CustomOptionLabel
              value={value}
              result="N"
              option={{
                label: 'No',
              }}
            />
          ),
          component: (
            <CustomOptionLabel
              optionAnswer={true}
              value={value}
              result="N"
              option={{
                label: 'No',
              }}
            />
          ),
          trigger: jump || trigger,
          // trigger: '18',
        },
      ],
    };
  };
  const handleStepChange = ({ step, steps }) => {};
  console.log('Result:', result); // Log the result object to the console

  return (
    <>
      <Dialog
        // onClose={handleChatEnd}
        open={isOpen}
      >
        {apiData && (
          <ChatBot
            handleStepChange={handleStepChange}
            toggleFloating={() => setIsOpen(!isOpen)}
            handleEnd={handleChatEnd}
            optionStyle={optionStyle}
            steps={allSteps}
            headerTitle={'KAYA'}
            floating={true}
            width="400px"
            opened={isOpen}
            botAvatar={operator}
          />
        )}
      </Dialog>
    </>
  );
}
