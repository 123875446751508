import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { REACT_APP_CHATBOT_BASE_URL } from '../../config';
import axios from 'axios';

export default function Confirm({ result, steps, triggerNextStep, message }) {
  const [data, setData] = useState({
    employee_count: {
      valid: true,
      label: 'Number of employees',
      value: steps['55']?.value,
    },
    average_salary: {
      valid: true,
      label: 'Average salary of employees',
      value: steps['66']?.value,
    },
    days_lost: {
      valid: true,
      label: 'Average number of sick days per employee',
      value: steps['10']?.value,
    },
    resignation_count: {
      valid: true,
      label: 'Total no of resignations last year',
      value: steps['13']?.value,
    },
    missing_percentage: {
      valid: steps[16]?.value === 1,
      label: 'Percentage of missed sales target',
      value: steps['18']?.value,
    },
    industry_id: {
      valid: true,
      label: 'Industry you are in',
      value: steps['477T']?.value?.value,
      options: steps['477T']?.value?.industries,
    },
    performance_score:
      steps['20']?.value === 1
        ? {
            valid: steps['20']?.value === 1,
            label: 'Employee engagement score this year',
            value: steps['221']?.value,
          }
        : {
            valid: true,
            label: 'How engaged are your employees',
            value: steps['201']?.message?.props?.option?.value,
            options: [
              { label: 'Not engaged', value: 18 },
              { label: 'Poorly engaged', value: 42 },
              { label: 'Engaged enough', value: 60 },
              { label: 'Very engaged', value: 75 },
              { label: 'Exceptionally engaged', value: 91 },
            ],
          },
    engagment_score_last_year: {
      valid: steps['20']?.value === 1,
      label: 'Employee engagement score last year',
      value: steps['25']?.value,
    },
    customer_experience_score:
      steps['351']?.value === 1
        ? {
            valid: steps['351']?.value === 1,
            label: 'Customer experience score this year',
            value: steps['35']?.value,
          }
        : {
            valid: true,
            label: 'How customers feel about your company',
            value: steps['471']?.message?.props?.option?.value,
            options: [
              { label: 'Extremely unhappy', value: 18 },
              { label: 'Very unhappy', value: 42 },
              { label: 'Happy enough', value: 60 },
              { label: 'Very Happy', value: 75 },
              { label: 'Exceptionally Happy', value: 91 },
            ],
          },
    customer_score_last_year: {
      valid: steps['351']?.value === 1,
      label: 'Customer experience score last year',
      value: steps['39']?.value,
    },
    annual_revenue: {
      valid: true,
      label: "Your company's current annual revenue",
      value: steps['473']?.value,
    },
    customers_count: {
      valid: true,
      label: 'Total number of customers',
      value: steps['475']?.value,
    },
    email: {
      valid: true,
      label: 'Your email address',
      value: steps['478']?.value,
    },
  });
  console.log('stepfss', steps);
  console.log('stepfss', data);
  const handleChange = (key, field, value) => {
    setData((prevData) => ({
      ...prevData,
      [key]: {
        ...prevData[key],
        [field]: value,
      },
    }));
    console.log('datass', data);
    console.log('datass', field);
    console.log('datass', value);
  };
  console.log('datass', data);
  const [disabled, setDiabaled] = useState(false);
  const handleProceed = () => {
    triggerNextStep({
      value: data,
      trigger: '479',
    });
    setDiabaled(true);
  };
  return (
    <Stack spacing={1}>
      <Typography variant="h6" textAlign={'center'}>
        {message}
      </Typography>
      <Stack width={'60%'} overflow={'hidden'} alignSelf={'center'} spacing={1}>
        {Object.entries(data).map(([key, item]) => (
          <Box
            key={key}
            display={item.valid ? 'flex' : 'none'}
            justifyContent={'space-between'}
            gap={'10px'}
          >
            <Box
              sx={{
                display: 'flex',
                flex: 3,
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                backgroundColor: '#3a5192',
                borderRadius: '10px',
                color: '#fff',
                padding: '0px 10px',
                fontSize: '16px',
              }}
            >
              {item.label}
            </Box>
            {!item?.options ? (
              <TextField
                size="small"
                id={key}
                disabled={!item.valid}
                inputProps={{
                  style: {
                    textAlign: 'center',
                    fontSize: '18px',
                    borderColor: '#3a5192',
                  },
                }}
                variant="outlined"
                value={item.value}
                sx={{
                  flex: 1.5,
                  textAlign: 'center',
                  '& fieldset': { borderColor: '#3a5192' },
                }}
                style={{ textAlign: 'center' }}
                onChange={(e) => handleChange(key, 'value', e.target.value)}
              />
            ) : (
              <Select
                inputProps={{
                  style: {
                    textAlign: 'center',
                    fontSize: '18px',
                    borderColor: '#3a5192',
                    overflow: 'hidden',
                  },
                }}
                sx={{
                  flex: 1.5,
                  overflow: 'hidden',
                  borderColor: '#3a5192',
                  '& fieldset': { borderColor: '#3a5192' },
                  '& svg': { color: '#3a5192' },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={item.value}
                size="small"
                onChange={(e) => handleChange(key, 'value', e.target.value)}
              >
                {item.options?.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>
        ))}
      </Stack>
      <Button
        size="small"
        variant="contained"
        disabled={disabled}
        color="primary"
        sx={{
          width: '60%',
          marginTop: '24px',
          alignSelf: 'center',
          fontSize: '20px',
          backgroundColor: '#3a5192',
          '&:hover': {
            backgroundColor: '#3a5192',
          },
          //   transform: hovered ? 'scale(1.1)' : 'scale(1)',
        }}
        onClick={handleProceed}
      >
        proceed with the calculation
      </Button>
    </Stack>
  );
}
