import { MenuItem, Select, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import operator from '../../../../src/assets/operator.jpg';
import axios from 'axios';
import { REACT_APP_CHATBOT_BASE_URL } from '../../../config';

export default function Industry({ steps, triggerNextStep }) {
  const [disabled, setDiabaled] = useState(false);

  const [selected, setSelected] = useState('');
  const handleChange = (event) => {
    setSelected(event.target.value);
    const convertedData =
      industries &&
      Object.entries(industries)?.map(([value, label]) => {
        return { label, value: parseInt(value) };
      });
    triggerNextStep({
      value: { value: event.target.value, industries: convertedData },
      trigger: '477',
    });
    setDiabaled(true);
    console.log('sas', steps);
  };
  console.log('sas', steps);
  const [industries, setIndustires] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      await axios
        .get(`${REACT_APP_CHATBOT_BASE_URL}/chatbot/getIndustries`)
        .then((response) => {
          // Store API response in state
          setIndustires(response.data);
        });
    };
    fetchPosts();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-end',
        gap: '10px',
        marginLeft: '0px',
      }}
    >
      {
        <img
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50% 50% 0px',
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 2px 0px',
          }}
          src={operator}
          alt=""
        />
      }
      <div
        style={{
          backgroundColor: '#3a5192',
          borderRadius: '18px 18px 18px 0px',
          color: '#fff',
          display: 'flex',
          gap: '10px',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          fontSize: '15px',
          padding: '15px',
        }}
      >
        <Stack>
          <Typography mb={2}>
            Choose the industry you are in from the options below
          </Typography>
          <Select
            inputProps={{
              style: {
                textAlign: 'center',
                fontSize: '18px',
                borderColor: '#fff !important',
                color: '#fff !important',
              },
            }}
            sx={{
              '&:hover': {
                borderColor: '#fff !important',
              },
              flex: 1.5,
              color: '#fff !important',
              pointerEvents: disabled ? 'none' : 'auto',
              borderColor: '#3a5192',
              '& fieldset': {
                borderColor: '#fff !important',
                color: '#fff !important',
              },
              '& fieldset:hover': {
                borderColor: '#fff !important',
                color: '#fff !important',
              },
              '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                color: '#fff !important',
              },
              '& svg': { color: '#fff !important' },
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selected}
            size="small"
            onChange={handleChange}
          >
            {industries &&
              Object.keys(industries)?.map((id) => (
                <MenuItem
                  // sx={{
                  //   display:
                  //     opt === 'Please Select your industry' ? 'none' : 'flex',
                  // }}
                  // disabled={opt === 'Please Select your industry'}
                  key={id}
                  value={id}
                >
                  {industries[id]}
                </MenuItem>
              ))}
          </Select>
        </Stack>
      </div>
    </div>
  );
}
